.header {
    background-color: #333;
    color: white;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
 
  
  .app-name {
    color: #ff6f00;
    font-size: 24px;
    margin: 0;
  }
  
  .dropdowns {
    display: flex;
    align-items: center;
  }
  
  .dropdowns select {
    margin-right: 10px;
    font-size: 16px;
    padding: 8px;
  }
  
  .run-button {
    background-color: #ff6f00;
    color: white;
    padding: 8px 16px;
    margin-left: 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .run-button:hover {
    background-color: #ff8124;
  }
  
  .dropdowns select:last-child {
    margin-right: 0;
    margin-left: 16px;
  }
  
  .app-container {
    display: flex;
    flex-direction: column;
    height: 100vh;
  }
  
  .editor-container {
    flex-grow: 1;
    overflow-y: auto;
  }
  
  .output-container {
    background-color: #333;
    color: white;
    padding: 10px;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    align-items: flex-start;
  }
  
  .output-container .input {
    flex-grow: 1;
    padding: 10px;
    background-color: #222;
    margin-right: 10px;
    border-radius: 4px;
  }
  
  .output-container .output {
    flex-grow: 1;
    padding: 10px;
    background-color: #222;
    border-radius: 4px;
  }
  
  .input-textarea,
  .output-textarea {
    width: 90%;
    height: 50px;
    background-color: #333;
    color: white;
    padding: 10px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
  }
  .popup-theme {
    background-color: #333;
    color: white;
    padding: 10px;
    border-radius: 4px;
  }
  
  .popup-theme .options {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 10px;
  }
  
  .popup-theme .options button {
    flex-grow: 1;
    background-color: #ff6f00;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
  
  .popup-theme .options button:hover {
    background-color: #ff8124;
  }
  
  .popup-theme .content {
    margin-top: 10px;
  }
  
  .join-form {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .join-form form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 10px;
  }
  
  .join-form input[type="text"] {
    width: 200px;
    height: 30px;
    padding: 5px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: none;
  }
  
  .join-form button {
    background-color: #ff6f00;
    color: white;
    padding: 8px 16px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
  }
  
  .join-form button:hover {
    background-color: #ff8124;
  }

  .room-info {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .room-info .room-id {
    font-size: 24px;
    margin-bottom: 10px;
    color: #ff6f00;
  }
  
  .room-info .room-desc {
    color: #fff;
    font-size: 16px;
  }
  
  .members-list {
  background-color: #333;
  color: white;
  padding: 10px;
}

.members-list h2 {
  color: #ff6f00;
  font-size: 24px;
  margin: 0;
}

.members-list ul {
  list-style: none;
  padding: 0;
}

.members-list .member-item {
  font-size: 16px;
  margin-bottom: 5px;
}

.group-chat {
  background-color: #333;
  color: black;
  padding: 10px;
  border-radius: 10px;
}
.messages-container {
  height: 300px; /* Adjust the height as needed */
  overflow-y: auto;
  scroll-behavior: smooth;
}
.messages-container::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.messages-container::-webkit-scrollbar-track {
  background: #f1f1f1; /* Color of the scrollbar track */
}

.messages-container::-webkit-scrollbar-thumb {
  background-color: #888; /* Color of the scrollbar thumb */
  border-radius: 4px; /* Rounded corners of the scrollbar thumb */
}

.messages-container::-webkit-scrollbar-thumb:hover {
  background-color: #555; /* Color of the scrollbar thumb on hover */
}

.messages {
  display: flex;
  flex-direction: column;
}

.group-chat .messages {
  display: flex;
  flex-direction: column;
}

.group-chat .message {
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 10px;
  margin-bottom: 10px;
}

/* .group-chat .message-content {
  background-color: #222;
} */

.group-chat .message-content p {
  margin: 0;
}

.group-chat .sent-message {
  align-self: flex-end;
  background-color: #fff;
}

.group-chat .received-message {
  align-self: flex-start;
  background-color: #fff;
}

.group-chat .message-input {
  display: flex;
  margin-top: 10px;
}

.group-chat .message-input input[type="text"] {
  flex-grow: 1;
  height: 30px;
  padding: 5px;
  border-radius: 4px;
  border: none;
}

.group-chat .message-input .send-button {
  background-color: #ff6f00;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 10px;
}

.group-chat .message-input .send-button:hover {
  background-color: #ff8124;
}